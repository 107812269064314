import React from "react"
import Layout from "../components/layout"
import "./control.css"
import HomePageYAMLData from "../../content/home.yaml"

export default () => (
  <Layout pageId={"/control"}>
    <div className='w-100 control_banner'>
      <div className='container  h-100'>
        <div className='row align-items-center h-100 '>
          <div className='col-12 '>
            <div className='col-auto px-0' style={{ fontSize: 70, fontWeight: "bold", color: "#fff" }}>
              {HomePageYAMLData.control_title}
              {/*质量管控*/}
            </div>
            <div className='w-100'/>
            <div>
              <div style={{ backgroundColor: "#fff", height: 2, width: 196, marginTop: 10 }}/>
            </div>
            <div className='w-100'/>
            <div className='col-8 px-0' style={{
              fontSize: 18,
              marginTop: 20,
              lineHeight: "30px",
              backgroundColor: "rgba(52, 87, 122, 0.13)",
            }}>
              <div style={{ padding: 28, color: "#fff" }}>{HomePageYAMLData.control_title_content}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className='container-fluid px-0'>
      <div className='row mx-0 align-items-center justify-content-center'>
        <div className='col-6 px-0 '>
          <div className='row mx-0 align-items-center justify-content-center'>
            <div className='col-lg-8 col-10 px-0'>
              <div className="control_msg_title">
                {HomePageYAMLData.control_category_title_1}
                {/*数据安全性*/}
              </div>
              <div className="control_msg_content">
                {/*首先我们对患者影像数据进行数据清洗，去除患者标识和信息，确保患者信息的隐私性和安全性。其次对数据进行分级，不同的数据被赋予相应的安全等级，从高到低，依次针对不同等级的数据采取不同的安全措施，以合理利用资源。同时我们采用授权机制，权限分别有增、删、改、查。授权基于角色，而不是具体的用户本身。授予不同用户相应的角色身份，每个角色身份根据“最小特权”原则分配该角色完成工作所必须的权限，而禁止授予该角色其它不必要的权限。同时，我们对试图进入数据库系统的用户实行身份验证机制，用户需要通过预先设定的账号密码通过验证，方能进入数据库系统。最后，我们对不同等级的数据进行不同间隔时长的备份。
                */}
                {HomePageYAMLData.control_category_title_1_content}
              </div>
              </div>
          </div>

        </div>
        <div className='col-6 px-0'>
          <img className={"w-100"} src='/images/control/Rectangle_4.png'/>
        </div>
      </div>
      <div className='row mx-0 align-items-center justify-content-center'>
        <div className='col-6 px-0'>
          <img className={"w-100"} src='/images/control/Rectangle_4_2.png'/>
        </div>
        <div className='col-6 px-0'>
          <div className='row mx-0 align-items-center justify-content-center'>
            <div className='col-lg-8 col-10 px-0'>
              <div style={{ color: "#454545", fontSize: 30 }}>
                {/*流程规范性*/}
                {HomePageYAMLData.control_category_title_2}
              </div>
              <div style={{ color: "#454545", fontSize: 14, lineHeight: "25px", marginTop: 25 }}>
                {HomePageYAMLData.control_category_title_2_content}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row mx-0 align-items-center justify-content-center' style={{ marginBottom: 40 }}>
        <div className='col-6 px-0'>
          <div className='row mx-0 align-items-center justify-content-center'>
            <div className='col-lg-8 col-10 px-0'>
              <div className="control_msg_title">
                {HomePageYAMLData.control_category_title_3}
                {/*结果准确性*/}
              </div>
              <div className="control_msg_content">
                {HomePageYAMLData.control_category_title_3_content}
              </div>
            </div>
          </div>
        </div>
        <div className='col-6 px-0'>
          <img className={"w-100"} src='/images/control/Rectangle_4_3.png'/>
        </div>
      </div>
    </div>
  </Layout>
)
